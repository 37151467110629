.navigationContainer {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 50%;
	margin: 0 auto;

	h6 {
		cursor: pointer;
		margin-bottom: 0px;
		font-size: 15px;
		font-family: Assistant-Bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.6;
		letter-spacing: normal;
	}
}