.filterContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  min-width: 147px;
  font-family: "Assistant-Bold";
  justify-content: center;
}
.active {
  color: white;
  background-color: rgb(74, 194, 206);
  padding: 4px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 1%;
}
