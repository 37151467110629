.redStatus,
.greenStatus,
.orangeStatus,
.greyStatus {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
}

.redStatus {
    background: #F9B4B4;
}

.greenStatus {
    background: #B6F3B8;
}

.orangeStatus {
	background: #fde2ba;
}

.greyStatus {
	background:rgb(243, 243, 243);
}