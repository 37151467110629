code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Assistant;
  src: url(../src/assets/fonts/assistant/Assistant-Regular.ttf);
}

@font-face {
  font-family: Assistant-Bold;
  src: url(../src/assets/fonts/assistant/Assistant-Bold.ttf);
}

@font-face {
  font-family: PTSans;
  src: url(../src/assets/fonts/pt_sans/PTSans-Regular.ttf);
}

/* Global Styles as specified on https://app.zeplin.io/project/5e46b0789e5465a9d6837e68/screen/5e46b0a5f6b2d8ab5470d92b */

/* Common styles in tags and classes*/

h1,
h2,
h3,
h4,
h5,
h6,
.s1,
.s2,
p,
.p2,
.c1,
.c2,
label {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

h1,
h3,
h6,
p,
.c1,
.c2,
label {
  line-height: 1.33;
}

.s1,
.s2,
.c2,
label {
  font-weight: bold;
}

p,
.p2,
.s1,
.s2,
.c1,
.c2,
label {
  font-family: Assistant;
}

.s1,
p {
  font-size: 14px;
}

.s2,
.p2,
.c1 {
  font-size: 12px;
}
.c2,
label {
  font-size: 13px;
}

/* Specific elements and classes styles */

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: PTSans;
}

h1 {
  font-size: 29px;
}

h2 {
  font-size: 26px;
  line-height: 1.15;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
  line-height: 1.2;
}

h5 {
  font-size: 20px;
  line-height: 1.35;
}

h6 {
  font-size: 17px;
}

/* Subtitles */

.s1 {
  line-height: 1.5;
}

.s2 {
  line-height: 1.75;
}

/* Pargraphs */

.p2 {
  line-height: 1.28;
}

/*  */

label {
  display: inline-block;
}

/* Buttons */

button {
  width: 156px;
  height: 32px;
  color: white;
  border-radius: 4px !important;
  font-size: 13px !important;
  border-style: none;
  font-weight: bold;
  font-family: Assistant;
  cursor: pointer;
}

button.primary:hover {
  opacity: 0.7;
  background-color: #fbb040;
}

button.primary:active {
  background-color: #ff9900;
}

/* Colors */

.primary {
  background-color: #fbb040;
}

.secondary {
  border: solid 1px #202020;
  background-color: white !important;
  color: black;
  font-family: Assistant-Bold;
}

table {
  font-family: Assistant;
}
/* Ant Design Custom Styles*/

.ant-tabs-nav .ant-tabs-tab-active {
  /* color: #4ac2ce!important; */
  font-weight: bold;
}

.ant-modal-body {
  padding: 13px;
}

.ant-modal-footer button {
  width: 168px;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  padding: 14px !important;
}

.ant-pagination-total-text {
  margin-right: auto;
  font-family: Assistant;
}
.modalStyles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 20px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
