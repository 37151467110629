.cardHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cardLogo {
    height: 40px;
    width: 40px;
  }
  .cardEditIcon {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}
