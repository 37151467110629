.menuContainer {
  background: white;
  border: 1px solid rgb(232, 232, 232);
  box-shadow: 0px 2px 4px 0px rgb(227, 227, 227);
  padding: 16px;
  max-height: 300px;

  .formContainer {
    label {
      font-weight: bold;
    }
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow-y: auto;
}
