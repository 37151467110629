.spinner-container {
  font-size: 25px;
}

.spinnerContainer {
  height: 100%;
  width: 100%;
  align-self: center;
}

.appContainer {
  width: 100%;
  margin: 0 auto;
}

.headerContainer {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 2;
  height: 128px;
}

.switchContainer {
  display: block;
  width: 80%;
  padding-top: 58px;
  margin: 0 auto;
}

@media (min-width: 1920px) {
  .switchContainer {
    width: 80%;
  }
}

.saveModal {
  width: 45% !important;
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
