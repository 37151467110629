.transactionDetailContainer {

}

.sourceDetailModal {
	width: 80% !important;

	:global {
		.ant-modal-body {
			padding: 0;
		}
	}
}