.dropdownContentContainer {
  min-width: 180px;
  height: 32px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Assistant-Bold;
  font-size: 12px;

  .parameterText {
    width: 100%;
    text-align: center;
  }
}
