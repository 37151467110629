* {
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.ant-table-thead {
  th.ant-table-column-sort {
    background: #e8e8e8 !important;
  }
}

.ant-table-column-sorters {
  width: 100%;
}

.ant-table-column-sorter-full {
  margin-left: auto;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: black;
}

.ant-collapse-content-box {
  padding: 0px !important;
}

.ant-tabs-tab {
  font-family: Assistant-Bold;
  font-size: 15px;
}

@primary-color: #4ac2ce;@tabs-title-font-size: 15px;@tabs-title-font-size-lg: 16px;@tabs-title-font-size-sm: 14px;@modal-footer-bg: #f3f3f3;@table-font-size: 13px;@table-header-bg: #e8e8e8;@table-padding-vertical: 6px;@table-padding-horizontal: 8px;