.reportContainer {
  width: 100px;
  height: 32px;

  cursor: pointer;
  a {
    color: black;
    font-family: "Assistant-Bold";
    display: flex;
    align-items: center;
  }
}
