.formGroup {
  margin-top: 20vh;

  h1 {
    padding-bottom: 1.25rem;
  }
  form {
    input {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;
      color: #495057;
      background-clip: padding-box;
      transition: border-color 0.15s;
    }
  }

  .loginFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.7rem;
  }
}

.link {
  font-family: Assistant;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #4ac2ce;
  cursor: pointer;
  margin-top: auto;
}

.passwordLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
