.sourceDetailDContainer {
  height: 521px;
  background: #f7f7f7;
  padding: 80px;
  .gridContainer {
    display: grid;
    grid-template-rows: repeat(6, min-content);
    grid-auto-flow: column;
  }
}
