.switchContainer {
	display: block;
	width: 80%;
	padding-top: 58px;
	margin: 0 auto;
}

@media (min-width: 1920px) {
	.switchContainer {
		width: 80%;
	}
}

