.userNameTitle {
  font-weight: bold;
}

.headerUser {
  .userName {
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
      margin-right: 24px;
      height: 23px;
      width: 23px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4ac2ce;
      background-color: rgba(#4ac2ce, 0.2);
      padding: 19px;
      font-size: 17px;
      font-weight: bold;
    }
    .userText {
      font-size: 11px;
    }
    .userCompany {
      font-weight: bold;
    }
  }
}

.cardUser {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .avatar {
    margin-top: 50%;
    margin-bottom: 30px;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 64px;
    color: #4ac2ce;
    background-color: rgba(#4ac2ce, 0.2);
  }
}

.colleagueUser {
  .userName {
    display: flex;
    align-items: center;
    cursor: pointer;
    .avatar {
      margin-right: 24px;
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4ac2ce;
      background-color: rgba(#4ac2ce, 0.2);
      padding: 20px;
      font-size: 21px;
      font-weight: bold;
    }
    .userText {
      font-size: 12px;
    }
  }
}

.inviteUser {
  .userName {
    display: flex;
    align-items: center;
    cursor: pointer;
    .avatar {
      margin-right: 24px;
      height: 56px;
      width: 56px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fbb040;
      background-color: rgba(251, 176, 64, 0.4);
      padding: 20px;
      font-size: 21px;
      font-family: Assistant-Bold;
    }
    .userText {
      font-size: 14px;
      color: #fbb040;
    }
  }
}
