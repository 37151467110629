.filtersContainer {
  width: 100%;
  .pageTitle {
    min-width: 100px;
    font-family: "Assistant-Bold";
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-right: 60px;
  }
  .searchContainer {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  display: flex;
  align-items: center;
  > div {
    margin-right: 10px;
  }
}

.iconContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  width: 147px;
  font-family: "Assistant";
  .active {
    color: white;
    background-color: rgb(74, 194, 206);
    padding: 4px;
    font-weight: bold;
    font-size: 12px;
    border-radius: 1%;
  }
}
