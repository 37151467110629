.loginContainer {
	height: 100%;
}

.loginForm {	
	width: 40vw;
	padding: 45px 8vw;

	h2, p, a {
		color: black;
	}

	label {
		font-size: 13px;
		margin-bottom: 0;
	}
	
	input {
		height: 36px;
		border-radius: 3px;
		border: solid 1px rgba(151, 151, 151, 0.4);
  		background-color: #ffffff;
		margin-top: 6px;
		margin-bottom: 6px;
	}
	
	button {
		width: 118px;
		height: 30px;
		border-radius: 5px;
		font-size: 11px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		border-style: none;
	}
	img {
		position: relative;
		top: 0;
		height: 40px;
	}
}

.particlesJs {

	display: flex;

	> div:first-child {
	
		background-image: linear-gradient(to right, #084A65, #4AC1CE)!important;
		width: 60vw;
		height: 100vh;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}



