.idFieldContainer {
  height: 64px;
  width: 300px;
  background: #f7f7f7;
  margin-top: 16px;

  .inputContainer {
    height: 32px;
    font-size: 13px;
  }

  .textContainer {
    font-family: "Assistant";
    padding: 8px;
    color: #979797;
    font-size: 12px;
  }
}

.transactionsDetailModal {
  width: 80% !important;

  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}

:global {
  .ant-table-body {
    overflow-y: auto !important;
  }
}
