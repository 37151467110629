.tableContainer {
  :global {
    .ant-table-body {
      overflow: scroll !important;
      min-height: 488px !important;
    }
  }
}

.transactionIDContainer {
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .transactionIcon {
    width: 8px;
  }
}

.customParam {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
