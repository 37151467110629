.addContainer {
	color: #fbb040;
	display: flex;
	align-items: center;
	width: 100px;
	cursor: pointer;
	
	.iconContainer {
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: Assistant-Bold;
	}
	
	.textContainer {
		font-size: 12px;
		font-family: Assistant-Bold;
	}
}