.cardStatus {
  margin-top: 13px;
  display: flex;
  align-items: center;

  .statusBlock {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}
