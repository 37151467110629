.formContainer {
  margin-top: 12px;
  width: 50%;
  margin-left: auto;

  input {
    height: 30px !important;
    font-size: 11px;
  }

  a,
  label {
    font-size: 13px;

    display: block;
    margin-bottom: auto;
    text-decoration: none;
  }
  .apiContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 0.45rem;
  }
  label {
    margin-bottom: 8px;
  }

  .uploadContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(151, 151, 151, 0.4);
    height: 32px;
    width: 100%;

    .fileName {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-family: Assistant;
    }

    .uploadContainer {
      margin-bottom: 0px;
      .uploadButton {
        margin-left: auto;
        width: 98px;
        height: 100%;
        background-color: rgba(151, 151, 151, 0.2);
        border: 1px solid #d5d5d5;
        border-radius: 0;
      }
    }
  }
}
